<template>
    <section class="skeleton-area fade-in">
        <div class="skeleton-area-portada">
            <div class="skeleton-area-titulo mt-1 heartbeat"></div>
            <div class="skeleton-img heartbeat"></div>
            <div class="skeleton-area-coordinador heartbeat ps-1"></div>
        </div>
        <div class="skeleton-area-info heartbeat"></div>
    </section>
</template>

<script>
export default {

}
</script>

<style lang="sass" scoped>
.skeleton-area
    $root:&

    display: flex
    flex-direction: column
    flex-wrap: wrap
    
    &-titulo
        width: 90%
        height: 32.5px
        background-color: #e4e6e9
    
    &-portada
        display: flex
        flex-direction: column
        .skeleton-img
            order:3
            width: 100%
            margin-bottom: 0px
            width: 360px
            height: 240px
            background-color: #e4e6e9

    &-coordinador
        order:2
        margin-top: 7px
        margin-bottom: 6px
        width: 95%
        height: 16px
        background-color: #e4e6e9

    &-info
        width: 93%
        height: 190px
        background-color: #e4e6e9
</style>